import { render, staticRenderFns } from "./Error403.vue?vue&type=template&id=15130fa0&scoped=true"
import script from "./Error403.vue?vue&type=script&lang=js"
export * from "./Error403.vue?vue&type=script&lang=js"
import style0 from "./Error403.vue?vue&type=style&index=0&id=15130fa0&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15130fa0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/SpMFxzSoQ/0/hero/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('15130fa0')) {
      api.createRecord('15130fa0', component.options)
    } else {
      api.reload('15130fa0', component.options)
    }
    module.hot.accept("./Error403.vue?vue&type=template&id=15130fa0&scoped=true", function () {
      api.rerender('15130fa0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/error-page/Error403.vue"
export default component.exports